.login_section {
  background-image: url("./sign_up_sign_in.png");
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
}
.login_form {
  max-width: 500px;
  margin: 0 auto;
  padding: 100px 0;
}

.logup_form {
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 0;
}

.login_form h2,
.logup_form h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 45px;
  color: #ff0000;
  margin-bottom: 40px;
}

.login_form form.signin-form input {
  width: 100%;
  background: transparent;
  padding: 25px 20px;
  border-radius: 10px;
  border: 2px solid #fff;
  font-style: normal;
  font-weight: normal;

  line-height: 25px;
  color: #ffffff;
  margin-bottom: 15px !important;
  opacity: 0.5;
}
.dob {
  color: white;
  opacity: 0.5;
  margin-bottom: 0 !important;
}
.signup-button {
  opacity: 1 !important;
}
.login_form form.signin-form button.form-control.btn.btn-primary.rounded.submit,
.logup_form .form-control.btn.btn-primary.rounded.submit {
  background: #ff0000;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 500;

  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 0;
  border: 2px solid #ff0000;
}
.login_form
  form.signin-form
  button.form-control.btn.btn-primary.rounded.submit:hover,
.logup_form .form-control.btn.btn-primary.rounded.submit:hover {
  background-color: #000000;
}
.signup_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  padding-top: 30px;
}
.signup_text p a {
  color: #ff0000;
  text-decoration: none;
  margin-left: 10px;
}

.signup_text {
  border-top: 2px solid #fff;
  margin-top: 40px;
}
.forget_password a {
  margin: 30px 0 0 0;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  color: #ff0000;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
}

.logup_form form.signup-form input,
.select {
  width: 100%;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #fff;
  font-style: normal;
  font-weight: normal;

  line-height: 25px;
  color: #ffffff;
  margin-bottom: 15px !important;
  opacity: 0.5;
  padding: 15px 15px;
  border-radius: 10px;
}
.select option:nth-child(n) {
  background-color: transparent !important;
  color: black;
}
.logup_form form.signup-form #togglePassword {
  position: absolute;
  top: 30%;
  right: 20px;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
}
.toggle {
  position: relative;
}
.copy_login ul {
  padding: 0;
  margin: 0;
}

.copy_login ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #ff0000;
}
.copy_social ul {
  margin: 0;
  padding: 0;
}
.copy_login ul li {
  list-style: none;
  display: inline-block;
  color: #fff;
  padding: 0 30px 0 0;
}
.copy_social ul li {
  list-style: none;
  display: inline-block;
  padding: 0 30px;
}

.copy_social ul li a {
  font-size: 25px;
  color: #fff;
}
.copy_social {
  text-align: center;
}
footer.login_footer {
  margin: 0;
  padding: 20px 0;
}
.toggle {
  position: relative;
}
#togglesignin {
  position: absolute;
  top: 37%;
  right: 20px;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
}

.forget_password a:hover {
  color: #fff;
}
.signup_text p a:hover {
  color: #fff;
}
.wallet_section {
  background-image: url("./connect_wallet.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.wallet_text {
  padding: 120px 0;
}

.wallet_text h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 80px;
  color: #ffffff;
}

.wallet_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #fff;
}

.wallet_text a.wallet_btn {
  background: #ff0000;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 15px;
  display: inline-block;
  border: 2px solid #ff0000;
}
.wallet_text a.wallet_btn:hover {
  border: 2px solid #ff0000;
  background: #000000;
  color: #ffffff;
}
.confirmation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 30vh;
}
/* .confirm-container {
  position: relative;
} */
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  /* color: #000000; */
}
.confirmation-msg {
  position: relative;

  text-align: center;
  width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  word-break: break-all;
}

.confirmation input {
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: 2px solid BLACK;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 15px;
  background-color: white;
  margin-bottom: 15px !important;
  opacity: 0.8;
}
.confirmation input::placeholder {
  color: black !important;
  opacity: 1;
}

#confirmation:hover {
  background-color: #ff0000 !important;
  opacity: 1;
}
.confirmation h1 {
  text-align: center;
  font-style: normal;
  font-size: 30px;
  color: #ff0000;
}
.hint {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  color: white;
  opacity: 0.8;
}
.hint p {
  margin: 5px 0;
}
.hint a {
  text-decoration: none;
  color: red;
  font-style: italic;
  font-weight: bolder;
}
.hint a:hover {
  /* font-size: 24px; */
  color: red;
}
.recovery {
  background: #ff0000;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 0;
  border: 2px solid #ff0000;
  width: 100%;
}
@media (max-width: 767px) {
  .wallet_text h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .wallet_text {
    padding: 80px 0;
  }
  .wallet_text p br {
    display: none;
  }
}
@media (max-width: 420px) {
  .wallet_text h1 {
    font-size: 28px;
    line-height: 28px;
  }
}
.error {
  color: #ff0000;
  margin-bottom: 30px !important;
}
