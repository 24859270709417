#entrance {
  width: 50%;
  float: left;
  margin: 1.5rem 1.5rem 1.5rem 0;
}
.Italic {
  font-style: italic !important;
}
.bold {
  font-weight: bold !important;
}
.underline {
  text-decoration: underline;
}
.Page_chapters_section {
	background-image: url("../Chapters/Sections/Images/Img\ 7-chap2-labrinth\ in\ rain\ banner.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Page_chapters_section {
	text-align: center;
	padding: 120px 0;
}

.Page_chapters_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
}

.tab_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 40px;
}

.tab_section p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  text-align: left;
  color: #ffffff;
}

.tab_section h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 30px;
}
.tab_section h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 30px;
}
.chapter_tab .nav {
  display: grid;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  border-bottom: none;
  margin-bottom: 40px;
}

.chapter_tab .nav li .nav-link {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  width: 100%;
}

.chapter_tab .nav li .nav-link:hover,
.chapter_tab .nav li .nav-link:focus,
.chapter_tab .nav li .nav-link:focus-visible {
  color: #ff0000;
  border: none;
  background: transparent;
}

.chapter_tab .nav li:hover,
.chapter_tab .nav li:focus,
.chapter_tab .nav li:focus-visible {
  color: #ff0000;
  border: none;
  background: transparent;
}

.chapter_tab .nav li .nav-link.active,
.chapter_tab .nav li.show .nav-link {
  color: #ff0000;
  background-color: transparent;
  border-color: transparent;
}

.chapter_tab {
  overflow-x: auto;
  overflow-y: hidden;
  height: auto;
}

/* width */

.chapter_tab::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.chapter_tab::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.chapter_tab::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
.page_title h1 {
  margin-top: 60px;
  text-align: center;
  color: #ff0000;
}
/* Handle on hover */

.chapter_tab::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.tab-content > .active {
  background-color: black !important;
}
@media (max-width: 1199px) {
  .chapter_tab .nav {
    grid-template-columns: 5fr 5fr 5fr;
  }
  .tab_section h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  /* .Page_chapters_section {
    text-align: center;
    padding: 80px 0;
}
.Page_chapters_section h1 {
    font-size: 43px;
    line-height: 50px;
} */
}
@media (max-width: 480px) {
  .chapter_tab .nav {
    grid-template-columns: 5fr 5fr;
  }
}
