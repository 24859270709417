.Page_team_section {
	background-image: url('../../../../../assets/banner/Meet-the-team.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Page_team_section {
	text-align: center;
	padding: 120px 0;
}

.Page_team_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #FFFFFF;
}



.nav_menu ul {
	padding: 0;
	margin: 0;
	text-align: center;
}

.nav_menu ul li {
	list-style: none;
	display: inline-block;
}

.nav_menu ul li a {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 31px;
	text-align: center;
	color: #FFFFFF;
}

.nav_menu ul li a:hover {
	color: #FF0000;
}

.nav_menu ul li a.active {
	color: #ff0000;
}



.team_text_section h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 50px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 50px;
}


.team_person {
    text-align: center;
    margin-bottom: 30px;
}

.team_person h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 30px;
}

.team_person h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    color: red;
}

.team_person img{
	width: 300px;
	height: 300px;
	object-fit: cover;
	border-radius: 50%;
}

@media (max-width: 767px) {
	.Page_more_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_more_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
}