.Page_more_section {
	background-image: url("../More/Sections/Images/more_banner.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Page_more_section {
	text-align: center;
	padding: 120px 0;
}

.Page_more_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #FFFFFF;
}
.more_text_section {
    text-align: center;
}

.more_text_section h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 40px;
}

.more_text_section p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}

.nav_menu ul {
	padding: 0;
	margin: 0;
	text-align: center;
}

.nav_menu ul li {
	list-style: none;
	display: inline-block;
}

.nav_menu ul li a {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 31px;
	text-align: center;
	color: #FFFFFF;
}

.nav_menu ul li a:hover {
	color: #FF0000;
}

.nav_menu ul li a.active {
	color: #ff0000;
}


.board_text_section ul {
    padding: 0;
    margin: 0 0 30px 0;
    display: grid;
    grid-template-columns: 6fr 6fr;
}

.board_text_section ul li {
	list-style: none;
}

.board_text_section h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 50px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 50px;
}




@media (max-width: 767px) {
	.Page_more_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_more_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
}