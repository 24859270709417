.TransferSection {
  background-image: url(".../../../../../../assets/banner/Transfer\ cards.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 120px 0;
  /* margin-bottom: 50px!important; */
}
.TransferSection h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
}
.container {
  max-width: 1300px;
  margin: 80px auto;
}
.transfer-card-body {
  /* margin-top: 80px; */
}
.transfer-card-body h4 {
  font-weight: bold;
  font-size: 48px;
  line-height: normal;
  text-align: left;
  color: #ff0000;
  margin-bottom: 30px;
  font-style: normal;

  line-height: 60px;
}
.transfer-card-body hr {
  color: #ff0000;
  height: 2px;
  opacity: 1;
}
#transfer-card-second-hr {
  color: white;
}
.transfer-card-body h5 {
  color: white;
  font-size: 2rem;
  margin: 30px 0;
}
.transfer-card-input,
.transfer-multiple-card-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.transfer-card-input p,
.transfer-multiple-card-input p,
#transfer-card-note {
  color: white;
  margin-bottom: 5px;
  font-size: 1.25rem;
}
#transfer-card-note {
  font-style: italic;
  opacity: 0.5;
  margin-top: -20px;
  margin-bottom: 20px;
}
.transfer-card-input input,
.transfer-multiple-card-input input {
  margin-right: 60px;
  padding: 5px 0 5px 30px;
  background: transparent;
  color: white;
  border: 2px solid #fff;
  border-radius: 8px;
  opacity: 0.5;
  /* width: 275px; */
  width: 400px;
  height: 50px;
}
#multiple-card-input {
  width: 400px;
}
.transfer-card-button button {
  width: 400px;
  /* max-width: 300px; */
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  background-color: #ff0000;
  color: white;
  margin: 30px 0;
  height: 50px;
  padding: 8px 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 1.4rem;
}
.transfer-card-button button:hover {
  border: 2px solid #ff0000;
  background-color: transparent;
}
/* @media only screen and (min-width: 901px) and (max-width: 1300px) {
  .transfer-card-input input,
  .transfer-multiple-card-input input {
    margin-right: 20px;
    width: 300px;
    height: 50px;
  }
  #multiple-card-input {
    width: 300px;
  }
  .transfer-card-button button {
    width: 300px;
  }
} */

@media only screen and (max-width: 650px) {
  .transfer-card-body {
    margin-top: 0;
  }
  .transfer-card-body h4 {
    font-weight: bold;
    font-size: 2rem;
  }
  .TransferSection h1{
    font-size: 24px!important;
  }
  .transfer-card-body h5 {
    color: white;
    font-size: 1.5rem;
    margin: 30px 0;
  }
  .transfer-card-input,
  .transfer-multiple-card-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .transfer-card-input p,
  .transfer-multiple-card-input p,
  #transfer-card-note {
    color: white;
    margin-bottom: 15px;
    font-size: 1rem;
  }
  .transfer-card-input input,
  .transfer-multiple-card-input input {
    margin-right: 0;
    width: 80vw;
    /* max-width: 300px; */
    height: 50px;
  }
  #multiple-card-input {
    width: 80vw;
    /* max-width: 300px; */
  }
  .transfer-card-button button {
    width: 80vw;

    padding: 8px 20px;
    font-size: 1rem;
  }
}
