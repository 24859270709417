.drag-and-card {
  display: flex;
  margin-top: 50px;
}
.card-area {
  width: 65%;
  margin: 15px;
}
.drag-area {
  width: 30%;
  height: 95vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0 20px;
  border-left: 2px solid red;
}
.btn2 {
  background-color: rgb(196, 15, 15) !important;
  color: white !important;
}
.btn2:hover {
  background-color: red !important;
}
.dnd_container-mobile {
  display: none;
}
@media (max-width: 650px) {
  .drag-and-card {
    flex-direction: column;
  }
  .card-area {
    width: 100%;
    margin: 0;
  }
  .card-area .content_box {
    padding: 0 !important;
  }
  .drag-area {
    width: 90%;
    margin: 0 auto!important;
    border-left: none !important;
  }
  .dnd_container {
    display: none;
  }
  .dnd_container-mobile {
    width: 90%;
    margin: 0 auto!important;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .drop-mobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .drag-right, .drag-left{
    margin: 20px 0!important;
  }
}
