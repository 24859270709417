.battle-information {
  width: 100vw !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10vh 0;
  z-index: 11111111111;
  margin: 0 auto;
}
.battle-info-child {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
  /* text-align: center; */
  width: auto;
  max-width: 900px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh !important;
  min-height: 70vh;
  margin: 0 auto;
  padding: 40px;
  background-color: black;
  border: 2px solid darkred;
  border-radius: 10px;
  word-wrap: break-all;
  word-break: break-all;
}
.battle-info-card {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.battle-info-card .winner-card,
.battle-info-card .losser-card ,
.battle-info-card .history 
{
  flex-basis: 50%!important;
  /* width: 50%; */
}
