.battle_btn {
  display: flex;
  justify-content: center;
  grid-template-columns: none;
  gap: 0;
}
.breeding-list .offset-md-1 {
  margin-left: 0 !important;
}
.breeding-list .col-md-10 {
  width: 100% !important;
}
.breeding-list .container{
    padding: 0!important;
}
.breeding-list h3{
    color: white!important;
}
.breeding-list .card-details{
    display: flex;
}
.breeding-list .card-details div{
    flex-basis: 33.333333333%;
    
}
.breeding-list .card-details p{
    margin: 0!important;
    text-transform: uppercase;
}
.breeding-list .card-details div p{
    text-align: left;
}