.popup {
  width: 100vw !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 25vh 0 20vh;
  z-index: 11111111111;
  margin: 0 auto;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: red;
  transform: scale(1.5);
  /* color: #000000; */
}

.popup-msg {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  width: auto;
  /* max-height: 80vh; */
  max-width: 800px;
  overflow-y: auto;
  max-height: 70vh!important;
  margin: 0 auto;
  padding: 40px;
  background-color: black;
  border: 2px solid darkred;
  border-radius: 10px;
  word-wrap: break-all;
  word-break: break-all;
}

.popup input {
  width: 50%;
  background: transparent;
  padding: 15px 20px;
  border-radius: 10px;
  border: 2px solid #fff;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 15px !important;
  opacity: 1;
}
.popup .button{
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.popup button {
  background: #ff0000;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 500;
  /* font-size: 20px; */
  line-height: 25px;
  color: #ffffff;
  padding: 10px 0;
  border: 2px solid #ff0000;
  width: 48%;
  /* margin: 0 auto; */
}
