.Page_roadmap_section {
  background-image: url("../../../../../assets/banner/Humanity-header-2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.Page_roadmap_section {
  text-align: center;
  padding: 120px 0;
}
.Page_roadmap_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
}
.Roadmap {
  margin: 50px auto;
  display: flex;
  justify-content: center;
}
.Roadmap-mobile {
  display: none;
}
.roadmap-tree {
  background-image: linear-gradient(to right, #ff682a , #ffeb6e);
    border-radius: 5px;
  width: 15px;
  height: auto;
}
.roadmap-left {
  padding-top: 225px;
}
.roadmap-right {
  padding-top: 75px;
}
.roadmap-left div,
.roadmap-right div {
  margin: 0 30px 200px;
  width: 40vw;
  min-height: 100px;
  height: auto;
}
.Roadmap h1 {
  text-align: center !important;
   color: red;
  font-family: 'Times Roman', cursive !important;
}
.Roadmap p {
  text-align: justify;
  background-color: transparent;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 21px!important;
  font-family: Garamond;
}

@media screen and (max-width: 650px) {
  .Roadmap {
    display: none;
  }

  .Roadmap-mobile {
    display: block;
    margin: 50px 20px;
  }

  .roadmap-mobile {
    display: flex;
    flex-direction: column;
  }
  .Roadmap-mobile div {
    margin-bottom: 80px;
  }
  .Roadmap-mobile h1 {
    text-align: center !important;
    color: red;
    font-family: "Nabla", cursive !important;

  }
  .Roadmap-mobile p {
    text-align: justify;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 21px!important;
    font-family: 'Niconne', cursive!important;
  }
}
