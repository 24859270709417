.play_container_section {
  background-image: url("../../../../assets//banner/Breeding-lab-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.play_container_section {
  text-align: center;
  padding: 120px 0;
  margin-bottom: 50px !important;
}

.play_container_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
  text-transform: capitalize;
}
.card-decks {
  max-width: 1024px;
  padding: 30px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
}
.card-decks h1{
  font-size: 24px!important;
  text-align: center;
}
.play-container img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.card-decks .cards {
  /* width: 30%; */
}
@media (max-width: 991px) {
  .card-decks {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .play_section {
    text-align: center;
    padding: 80px 0;
  }
  .play_section h1 {
    font-size: 43px;
    line-height: 50px;
  }
  .card-decks {
    grid-template-columns: repeat(1, 1fr);
  }
}
