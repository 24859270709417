.loader-container{
    width: 100%;
    margin: 0 auto;
}
.card-loader {
    border: 16px solid #f3f3f3!important; /* Light grey */
    border-top: 16px solid red!important; /* Dark Green */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-top: 10%;
    margin-left: 45%;
    animation: spinloader 2s linear infinite;
}
.card-loader img{
    height : 120px;
    width : 120px;
    animation: spinlogo 2s linear infinite;
}
@keyframes spinloader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes spinlogo {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
}