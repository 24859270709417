.Page_plotsummary_section {
	/* background-image: url("../PlotSummary/Sections/Images/the-origin.png"); */
	background-image: url("../../../assets/sliderImage/image18.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    height: 480px;
}

.Page_plotsummary_section {
	text-align: center;
	/* padding: 120px 0; */
}

.Page_plotsummary_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
}
.origin_text_box h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
}

.origin_text_box p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}


.origin_text_box a.origin {
    border: 2px solid #EC0909;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 8px 40px;
}
@media (max-width: 767px) {
	.Page_plotsummary_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_plotsummary_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
	}