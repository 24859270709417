html {
  scroll-behavior: smooth;
}

.CardCollectionSection {
  background-image: url("../../../assets/banner/Card-collection-header-4.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 480px;
}

.CardCollectionSection {
  text-align: center;
  padding: 50px 0 90px;
}

.CardCollectionSection h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 100px;
}
.card_tab .nav-tabs {
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  border: none;
  margin: 0 0 50px 0;
  gap: 20px;
}

.card_tab .nav-tabs .nav-item .nav-link {
  width: 100%;
  background: transparent;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
}
.card_tab .nav-tabs .nav-link.active,
.card_tab .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.card_tab h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: left;
  color: #ff0000;
}

.card_tab p {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}

.custom_tab_text h3 {
  font-size: 28px;
  color: #fff;
  text-align: left;
  font-weight: bold;
}

.custom_tab_text p {
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
}
.card_tab .row {
  row-gap: 40px;
}
.card_tab .row {
  row-gap: 40px;
}

.scroll_link {
  margin-bottom: 40px;
}

.scroll_link ul {
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  gap: 15px;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
}

.scroll_link ul li {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.scroll_link ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: capitalize;
}

.scroll_link ul li a:hover {
  color: #ff0000;
}

.content_box {
  /* overflow-y: hidden;
  height: auto;
  overflow-x: hidden; */
  padding: 0 50px 0 0;
}
.custom_tab_text a:not(#getting-started-link) {
  border: 2px solid #ff0000;
  padding: 8px 30px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
}
.custom_tab_text a:not(#getting-started-link):hover {
  color: #ffffff;
}
.content_box.cost_fee_tab {
  overflow: hidden;
  height: auto;
}

.custom_tab_text.cost_fee p {
  margin: 0 0 5px 0;
}

.custom_tab_text.cost_fee hr {
  color: #ff0000;
  opacity: 1;
}
/* width */

.content_box::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.content_box::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.content_box::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */

.content_box::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.card_box h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #ffffff;
  /* margin-top: 30px; */
}

.card_info {
  display: grid;
  grid-template-columns: 2fr 2fr;

  align-items: center;
}

.card_type p,
.card_rank p {
  text-align: left;
  margin: 0;
  text-align: left;
  margin: 0;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  font-weight: normal;
}

.card_type p strong,
.card_type p span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.card_type p span {
  font-weight: normal;
}

.card_rank p strong,
.card_rank p span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.card_rank p span {
  font-weight: normal;
}
.battle_box {
  position: relative;
  height: 130px;
}

.battle_box ul {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 15px;
}

.battle_box ul li {
  display: inline-block;
}

.battle_proess {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -30px;
  top: auto;
}
.battle_proess h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
}

.battle_proess h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: #ff0000;
}
.battle_proess .progress {
  max-width: 230px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #000 !important;
}


.battle_proess .progress .color-one {
  background-color: rgb(255, 0, 0)
}
.battle_proess .progress .color-two {
  background-color: rgb(255, 0, 125);
}
.battle_proess .progress .color-three {
  background-color: rgb(125, 125, 0);
}
.battle_proess .progress .color-four {
  background-color: rgb(0, 125, 0);
}
.battle_proess .progress .color-five {
  background-color: rgb(0, 255, 0);
}

.color-one {
  color: rgb(255, 0, 0)!important
}
.color-two {
  color: rgb(255, 0, 125)!important;
}
.color-three {
  color: rgb(125, 125, 0)!important;
}
.color-four {
  color: rgb(0, 125, 0)!important;
}
.color-five {
  color: rgb(0, 255, 0)!important;
}

.cards_menu_list ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  margin: 0 0 50px 0;
}
.cards_menu_list ul li {
  flex-basis: 20%;
  margin: 0 10px;
}
.cards_menu_list .nav_menu ul li {
  list-style: none;
  display: inline-block;
}
.cards_menu_list ul .nav-item .nav-link {
  width: 100%;
  background: transparent;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
}
.cards_menu_list ul .nav-link.active,
.cards_menu_list ul .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.content_box h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: normal;
  text-align: left;
  color: #ff0000;
  margin-bottom: 30px;
}
.card_box {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .card_tab .nav-tabs .nav-item .nav-link {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .card_tab .nav-tabs {
    grid-template-columns: 5fr 5fr 5fr;
  }
}
@media (max-width: 800px) {
  .CardCollectionSection {
    text-align: center;
    padding: 0 0;
  }
  .CardCollectionSection .row {
    grid-gap: 0 !important;
    margin-top: -60px!important;
  }
  .CardCollectionSection h1 {
    font-size: 2.5rem;
    line-height: 35px;
    margin: 20px 0;
  }
  .CardCollectionSection h3 {
    font-size: 1.5rem !important;
    line-height: 25px !important;
  }
  .CardCollectionSection h4 {
    font-size: 1.5rem !important;
    line-height: 25px !important;
  }
  .CardCollectionSection .row {
    row-gap: 50px;
  }
  .content_box {
    padding: 0 20px 0 0;
  }
}

@media (max-width: 520px) {
  .cards_menu_list ul {
    flex-direction: column;
  }
  .cards_menu_list ul li {
    margin: 10px 0 !important;
  }
  .card_tab .nav-tabs {
    grid-template-columns: 5fr 5fr;
    gap: 10px;
  }
  .card_tab .nav-tabs .nav-item .nav-link {
    font-size: 12px;
  }
}
