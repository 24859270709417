.sales-record-banner {
  background-image: url(../Images/human_header_metaverse.png);
  height: 480px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1024px) {
  .sales-record-banner {
    height:360px;
    background-size: contain;
  }
}
