.wallet-container {
  width: 100%;
  margin: 0 auto;
  
}
.container {
  margin: 0;
}

.wallet-section-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-section-button button {
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: white;
  margin: 10px;
  height: 50px;
  padding: 8px 30px;
  font-family: "Orbitron", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 2px solid #ff0000;
}
.wallet-section-button .active {
  background-color: #ff0000;
}
.wallet-section-button button:hover {
  background-color: #ff0000;
}
.connect-wallet,
.disconnect-wallet {
  max-width: 1300px;
  margin: 50px auto;
}
.connect-wallet h1,
.disconnect-wallet h1 {
  color: #ff0000;
}
.wallet-box {
  display: flex;
  border: 1px solid white;
  border-radius: 20px;
  justify-content: center;
  color: white;
}
.wallet-box h2 {
  color: white;
  font-size: 25px;
}
.wallet-box-username {
  flex-grow: 1;
}
.wallet-box-address {
  flex-grow: 2;
  border-left: 1px solid white;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
}
.wallet-box-address p{
  word-break: break-all;
}

.wallet-box-address div {
  padding: 0 20px;
}
.wallet-box-address div:nth-child(1) {
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}
.wallet-box-address div:nth-child(2) {
  padding-top: 10px;
}
.wallet-connected,.wallet-not-connected {
  flex-grow: 1;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.wallet-not-connected{
  flex-direction: column;
}
.wallet-connected div:nth-child(1){
 
  border-right: 2px solid white;
  opacity: 1!important;
}

.wallet-connected div{
  height: 100%;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wallet-connected div div{
  flex-direction: column;
}
.wallet-box-username
 {
  padding: 20px;
}
.wallet-box-address {
  padding: 20px 0;
}

#checked,
#checked h2 {
  opacity: 1;
  color: #ff0000;
}
@media only screen and (min-width: 601px) and (max-width:1300px){
    .connect-wallet,
    .disconnect-wallet {
      padding: 0 30px;
    }
}
@media only screen and (max-width: 600px) {
 
  .wallet-section-button button {
    height: 60px;
    padding: 0 10px;
    font-size: 0.8rem !important;
  }
  .connect-wallet,
  .disconnect-wallet {
    padding: 0 20px;
  }
  .wallet-box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    word-break: break-all;
  }
  .wallet-box svg{
    margin-top: 10px!important;
  }
  .wallet-box-address {
    border-left: none;
    border-right: none;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
  .wallet-box-address div:nth-child(1) {
    border-bottom: 1px solid white;
  }
}
