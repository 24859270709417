.confirmation_heading ul {
  display: grid;
  grid-template-columns: 5fr 2fr 5fr;
  margin: 30px 0 30px 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.confirmation_heading ul li {
  text-align: center;
}

.confirmation_heading ul li img {
  width: 180px;
  height: 180px;
}

.confirmation_vs {
  width: 110px;
  text-align: center;
  border: 2px solid #ff0000;
  border-radius: 50%;
  height: 110px;
  margin: 0 auto;
}

.confirmation_vs strong {
  color: #ff0000;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110px;
}

.confirmation_heading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: left;
  color: #ffffff;
}
.card_info_text h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #ff0000;
  margin-top: 30px;
}
.card_info_text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
}
.card_list_box {
  margin-top: 20px;
}
.card_list_box ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin: 0;
  padding: 0;
  list-style: none;
}
.card_list_box ul li {
  padding: 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.card_list_box ul li span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ff0000;
}

.enlist_card h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
  color: #ff0000;
}

.enlist_card {
  margin-top: 30px;
}

.enlist_card p {
  border-left: 1px solid #ff0000;
  padding-left: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding-top: 25px;
}

.enlist_card p span {
  font-size: 28px;
}
.card_img {
  /* padding-top: 28px; */
}

.enlist_card .form-floating textarea {
  border-bottom: 2px solid #434343;
  border-radius: 0;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0;
  color: #fff;
  height: 100px;
}
.enlist_card .form-floating .form-control:focus {
  box-shadow: none;
}
.enlist_card .form-floating label {
  font-style: italic;
  color: #fff;
  font-size: 16px;
  padding: 0;
}
.enlist_card a {
  padding: 10px 30px;
  border: 2px solid #ff0000;
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
  margin-top: 40px;
  width: 100%;
}
