.Page_buynft_section {
	background-image: url("../../../../../assets/banner/Buy-NFTs.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Page_buynft_section {
	text-align: center;
	padding: 120px 0;
}

.Page_buynft_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #FFFFFF;
}

.buynft_text_section {
  text-align: center;
}

.buynft_text_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 40px;
}

.buynft_text_section p {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
}
.giveaway h2{
  color: red;
  font-size: 2.5rem;
  margin-bottom: 20px;
}