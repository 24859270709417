html {
  scroll-behavior: smooth;
}
.GuideSection{
  background-image: url("../Guide/Sections/Images/guide_header.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.AnatomySection {
  background-image: url("../../../assets/banner/Card-anatomy.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.CostFeeSection{
  background-image: url("../../../assets/banner/cost-and-fees.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.FAQSection{
  background-image: url("../../../assets/banner/FAQ.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.GameFeature{
  background-image: url("../../../assets/banner/Game-features.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.GettingSection{
  background-image: url("../../../assets/banner/Getting-started.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.MarketplaceSection{
  background-image: url("../../../assets/banner/Marketplace-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.WeaponSection{
  background-image: url("../../../assets/banner/Weaponry.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.AnatomySection ,.CostFeeSection,.FAQSection,.GameFeature,.GettingSection,.MarketplaceSection,.WeaponSection,.GuideSection{
  text-align: center;
  padding: 120px 0;
}

.AnatomySection h1,.CostFeeSection h1,.FAQSection h1,.GameFeature h1,.GettingSection h1 ,.MarketplaceSection h1,.WeaponSection h1,.GuideSection h1{
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
}
.guide_menu_list ul {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
  border: none;
  margin: 0 0 50px 0;
  gap: 20px;
}

.guide_menu_list ul .nav-item .nav-link {
  width: 100%;
  background: transparent;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
}
.guide_menu_list ul .nav-link.active,
.guide_menu_list ul .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.guide_tab .content_box h4 {
  text-align: center;
}
.guide_tab h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #ff0000;
}

.guide_tab p {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}

.custom_tab_text h3 {
  font-size: 28px;
  color: #fff;
  text-align: left;
  font-weight: bold;
}

.custom_tab_text p {
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
}
.guide_tab .row {
  row-gap: 40px;
}
.guide_tab .row {
  row-gap: 40px;
}

.scroll_link {
  margin-bottom: 40px;
}

.scroll_link ul {
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  gap: 15px;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
}

.scroll_link ul li {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.scroll_link ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: capitalize;
}

.scroll_link ul li a:hover {
  color: #ff0000;
}

.content_box {
  overflow-y: hidden;
  height: auto;
  overflow-x: hidden;
  padding: 0 50px 0 0;
}

.custom_tab_text a:not(#getting-started-link) {
  border: 2px solid #ff0000;
  padding: 8px 30px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
}
.custom_tab_text a:not(#getting-started-link):hover {
  color: #ffffff;
}
.content_box.cost_fee_tab {
  overflow: hidden;
  height: auto;
}

.custom_tab_text.cost_fee p {
  margin: 0 0 5px 0;
}

.custom_tab_text.cost_fee hr {
  color: #ff0000;
  opacity: 1;
}
/* width */

.content_box::-webkit-scrollbar {
  width: 15px;
}
.content_box {
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgb(202, 202, 202);
}

/* Track */

.content_box::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.content_box::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */

.content_box::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.custom_tab_text ul {
  padding: 0;
}

.custom_tab_text ul li {
  list-style: none;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}
.guide_tab .col-md-6 {
  margin-bottom: 30px;
}
.custom_tab_text {
  margin-top: 0;
}
.faq_box {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden;
  padding: 0 50px 0 0;
}
/* width */

.faq_box::-webkit-scrollbar {
  width: 15px;
}
.faq_box {
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgb(202, 202, 202);
}

/* Track */

.faq_box::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.faq_box::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */

.faq_box::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.faq_tab .nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.faq_tab .nav-tabs .nav-link {
  margin-bottom: 0;
  background: none;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #fff;
  font-size: 18px;
  width: 100%;
  text-align: left;
}
.faq_tab .nav-tabs .nav-link:hover,
.faq_tab .nav-tabs .nav-link:focus {
  border-color: transparent;
  isolation: isolate;
  color: #ff0000;
}
.faq_tab .nav-tabs .nav-link.active,
.faq_tab .nav-tabs .nav-item.show .nav-link {
  color: #ff0000;
  background-color: transparent;
  border-color: transparent;
}
.faq_tab_section h2.faq_heading {
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: #ff0000;
  margin: 40px 0;
}
.faq_tab h3 {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 30px;
}
.faq_box .accordion .accordion-item {
  background: transparent;
  border: none !important;
}
.faq_box .accordion .accordion-item .accordion-header button.accordion-button {
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.faq_box .accordion .accordion-item .accordion-body p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}
.faq_box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("../Guide/SubPages/Faq/Images/polygon.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.faq_box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
ul.rank_order {
  display: grid;
  grid-template-columns: 2fr 7fr;
  padding: 0;
  list-style: none;
  gap: 10px;
}

ul.rank_order li {
  color: #fff;
  font-size: 16px;
}

.cost-flex-box {
  display: flex;
}
.cost-flex-box h3:nth-child(3),
.cost-flex-box p:nth-child(3) {
  flex-basis: 25%;
  text-align: center;
}
.cost-flex-box h3:nth-child(2),
.cost-flex-box p:nth-child(2) {
  flex-basis: 25%;
}
.cost-flex-box h3:nth-child(1),
.cost-flex-box p:nth-child(1) {
  flex-basis: 50%;
}

@media (max-width: 1199px) {
  .guide_menu_list ul .nav-item .nav-link {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .guide_menu_list ul {
    grid-template-columns: 4fr 4fr 4fr;
  }
}

@media (max-width: 767px) {
  .AnatomySection, .FAQSection,.CostFeeSection,.GameFeature,.GettingSection ,.MarketplaceSection,.WeaponSection,.GuideSection{
    text-align: center;
    padding: 80px 0;
  }
  .AnatomySection h1,.CostFeeSection h1, .FAQSection h1,.GameFeature h1 ,.GettingSection h1,.MarketplaceSection h1,.WeaponSection h1,.GuideSection h1{
    font-size: 43px;
    line-height: 50px;
  }

  .faq_tab .nav-tabs .nav-link {
    color: #fff;
    font-size: 13px;
    text-align: left;
  }
  .faq_box {
    padding: 0 10px 0 0;
  }
}

@media (max-width: 520px) {
  .guide_menu_list ul {
    grid-template-columns: 4fr 4fr;
    gap: 10px;
  }
  .guide_menu_list ul .nav-item .nav-link {
    font-size: 12px;
  }
  .guide_tab h4 {
    font-size: 35px;
    line-height: 40px;
  }
  .faq_box
    .accordion
    .accordion-item
    .accordion-header
    button.accordion-button {
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }
  .faq_box .accordion .accordion-item .accordion-body p {
    font-size: 16px;
    line-height: 25px;
  }
  .faq_tab h3 {
    font-size: 25px;
  }
}
