.main_section {
  display: flex;
}
.main_component {
  flex-basis: 68%;
}
.dnd_component {
  flex-basis: 32%;
}
.card_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.card_section {
  max-width: 500px;
  margin: 20px;
}
.dnd_container li {
  /* position: -webkit-sticky;
  position: sticky;
  top: 0; */
  /* height: 35vh;
  list-style: none; */
}
.dnd_container {
  display: flex;
  flex-direction: column;
  height: 94vh;
}
.dnd_container button{
    margin: 0 15px;
}
.drag-right,
.drag-left {
  /* max-height: 200px; */
  margin: 15px !important;
}
.plus-sign {
  margin: 0 auto;
  /* width: 100%; */
  text-align: center;
  line-height: 1.3;
  width: 70px;
  height: 70px;
  /* border: 1px solid red; */
  /* border-radius: 50%; */
}
.plus-sign strong {
  text-align: center;
  color: red;
  font-size: 48px;
  /* width: 50px; */
}
