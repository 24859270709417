.Page_weapon_section {
  background-image: url("../../../../../assets/banner/Weapons.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Page_weapon_section {
  text-align: center;
  padding: 120px 0;
}

.Page_weapon_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
}

.nav_menu ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.nav_menu ul li {
  list-style: none;
  display: inline-block;
}

.nav_menu ul li a {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
}

.nav_menu ul li a:hover {
  color: #ff0000;
}

.nav_menu ul li a.active {
  color: #ff0000;
}

.weapons_list ul {
  padding: 0;
  margin: 0 0 30px 0;
  /* display: grid;
  grid-template-columns: 6fr 2fr; */
}

.weapons_list ul li {
  list-style: none;
}

.weapons_text_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 50px;
}

.weapons_content {
  display: grid;
  grid-template-columns: 4fr 6fr;
  position: relative;
  width: 100%;
}

.name_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.weapons_name {
  /* display: grid; */
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
}
.weapons_img {
  border: 2px solid #ffffff;
  padding: 10px;
  margin-right: 20px;
  background: #fff;
  max-width: 350px;
  /* width: 100%; */
}
.weapons_img img {
  /* max-width: 250px !important; */
  max-height: 400px !important;
  /* width: 100%; */
}
.weapons_rank ul {
  position: absolute;
  bottom: 20px;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  width: 50%;
}
/* .weapons_rank ul li {
  display: inline-block;
  width: 100%;
  text-align: left;
} */
.weapons_rank p {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #ff0000 !important;
}
/* .weapons_name span {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
} */

.weapons_name h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: initial;
  color: #ffffff;
}

.weapons_rank ul p span {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: inherit;
  color: #ff0000;
}
.weapons_tab ul {
  text-align: center;
  justify-content: center;
  border: none;
  margin-bottom: 40px;
}
.weapons_tab .nav-tabs .nav-link.active,
.weapons_tab .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
}
.weapons_tab .nav-tabs .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.weapons_tab .nav-tabs .nav-link:hover,
.weapons_tab .nav-tabs .nav-link:focus {
  border-color: transparent;
  isolation: isolate;
  color: #ff0000;
}

.more_menu_list {
  margin-top: 50px;
}
.weapons_list .product_price p {
  font-size: 24px;
  color: #fff;
  font-weight: normal;
  margin: 0;
}

.weapons_list .product_price strong {
  font-size: 35px;
  font-weight: 800;
  color: #fff;
}
.weapons_list {
  overflow-y: hidden;
  height: auto;
  overflow-x: hidden;
  padding: 0 50px 0 0;
}
/* width */

.weapons_list::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.weapons_list::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.weapons_list::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
.text-end {
  padding: 20px 0 0 0;
}
@media (max-width: 767px) {
  .Page_weapon_section {
    text-align: center;
    padding: 80px 0;
  }
  .Page_weapon_section h1 {
    font-size: 36px !important;
    line-height: 50px;
  }
  .name_price {
    flex-direction: column;
  }
}
