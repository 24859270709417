.Page_metaverse_section {
	background-image: url("../Metaverse/Sections/Images/human_header_metaverse.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
    height: 480px;
}

.Page_metaverse_section {
	text-align: center;
	/* padding: 120px 0; */
}

.Page_metaverse_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
}
.metaverse_text_box h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 30px;
}

.metaverse_text_box p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}

.image_text_box_section h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: #FF0000;
    margin-bottom: 30px;
}

.image_text_box_section  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
}

.image_text_box_section ul {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0 0 0;
    padding: 0;
}

.image_text_box_section ul li {
    text-align: left;
    list-style: none;
    margin:  15px;
}
.image_text_box_section ul li a:hover {
    background: #ff0000;
}
.image_text_box_section ul li a {
    border: 2px solid #EC0909;
    box-sizing: border-box;
    border-radius: 10px;
    color: #ffffff;
    padding: 8px 20px;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
}
section.image_text_box_section .row {
    row-gap: 40px;
}


@media (max-width: 1199px) {
.image_text_box_section ul {
   flex-direction: column;
}
}

@media (max-width: 767px) {
	.Page_metaverse_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_metaverse_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
	}

    @media only screen and (max-width: 1024px) {
        .Page_metaverse_section {
          height:360px;
          background-size: contain;
        }
      }

