.deceased,.card_img {
  position: relative;
}
.deceased-tag {
  /* display: none; */
  transform: rotate(-45deg) scale(1.5);
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 111;
  /* transform: ; */
}
.deceased-tag img{
  max-width: 100%;
}
.rankImage {
  width: 100px;
  position: absolute;
  top: 10px;
  right: 0;
}
.extremis-logo{
  width: 100px;
  position: absolute;
  top: 10px;
  left: 20px;
}
.word-wrapping h3 ,.word-wrapping p:not(.popup-msg p){
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  width: 100%!important;
}
.Deserter{
  width: 100%;
  border: 5px dotted white;
  border-radius: 3%;
}
.Private {
  width: 100%;
  border: 5px solid #f8f2f2;
  border-radius: 3%;
}
.Second-Lieutenant {
  width: 100%;
  border: 5px solid #1abc9c;
  border-radius: 3%;
}
.First-Lieutenant {
  width: 100%;
  border: 5px solid #186a3b;
  border-radius: 3%;
}
.Captain {
  width: 100%;
  border: 5px solid #c12e8e;
  border-radius: 3%;
}
.Major {
  width: 100%;
  border: 5px solid #7f70a4;
  border-radius: 3%;
}
.Lieutenant-Colonel {
  width: 100%;
  border: 5px solid rgb(92, 8, 151);
  border-radius: 3%;
}
.Colonel {
  width: 100%;
  border: 5px solid rgb(178, 32, 32);
  border-radius: 3%;
}
.Brigadier-General {
  width: 100%;
  border: 5px solid skyblue;
  border-radius: 3%;
}
.Major-General {
  width: 100%;
  border: 5px solid rgb(22, 149, 199);
  border-radius: 3%;
}
.Lieutenant-General {
  width: 100%;
  border: 5px solid rgb(17, 77, 189);
  border-radius: 3%;
}
.General {
  width: 100%;
  border: 5px solid rgb(5, 13, 97);
  border-radius: 3%;
}
.Legendary {
  width: 100%;
  border: 5px solid rgb(24, 23, 23);
  border-radius: 3%;
}
.Genesis {
  width: 100%;
  border: 5px solid gold;
  border-radius: 3%;
}
/* GENESIS	#F4D03F	244, 208, 63 */
/* 1-5	#F39C12	243, 156, 18 */
/* 6-10	#186A3B	24, 106, 59 */
/* 11-15	#1ABC9C	26, 188, 156 */
/* 16-20	#2E86C1	46, 134, 193 */
/* 21-25	#85C1E9	133, 193, 233 */
/* 26-30	#D2B4DE	210, 180, 222 */
/* 31-35	#7D3C98	125, 60, 152 */
/* 36-40	#566573	86, 101, 115 */
/* 41-45	#A2D9CE	162, 217, 206 */
/* 46-50	#E74C3C	231, 76, 60 */
/* 51-99	#CD7F32	205, 127, 50 */
/* 100+	#BFC9CA	191, 201, 202 */
