.Leader_section {
  background-image: url("../../../../../assets/banner/Leaderboard.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Leader_section {
  text-align: center;
  padding: 120px 0;
}

.Leader_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
}

.nav_menu ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.nav_menu ul li {
  list-style: none;
  display: inline-block;
}

.nav_menu ul li a {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
}

.nav_menu ul li a:hover {
  color: #ff0000;
}

.nav_menu ul li a.active {
  color: #ff0000;
}

.board_text_section ul {
  padding: 0;
  margin: 0 0 30px 0;
  display: grid;
  grid-template-columns: 6fr 6fr;
}

.board_text_section ul li {
  list-style: none;
}

.board_text_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  color: #ff0000;
  margin-bottom: 50px;
}

.profile {
  display: flex;
  gap: 15px;
  align-content: center;
  vertical-align: middle;
}

/* .profile .profile_img {
	border-radius: 50%;
	width: 80px;
	height: 80px;
	padding: 15px;
} */

.profile img {
  /* padding: 10px; */
  /* width: 111.8px; */
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #fff;
}
.user_name span {
  color: #ffffff;
  font-size: 20px;
}

.user_name h5 {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.info_user p {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #ff0000;
  margin: 0;
  line-height: 20px;
}

.info_user strong {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: #ff0000;
}
.profie_img {
  min-width: 75px;
}
.leaderboard-child {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_name {
  word-break: break-all;
}
@media (max-width: 767px) {
  .Leader_section {
    text-align: center;
    padding: 80px 0;
  }
  .Leader_section h1 {
    font-size: 43px;
    line-height: 50px;
  }
  .board_text_section h2 {
    font-size: 2rem !important;
  }
}
@media (max-width: 650px) {
  .leaderboard-child {
    /* flex-direction: column; */
    /* align-items: ; */
    font-size: 1rem !important;
    justify-content: space-between;
  }
}
