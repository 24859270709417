.breeding_section {
	background-image: url("../../../../../assets/banner/Breeding-lab-banner.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.breeding_section {
	text-align: center;
	padding: 120px 0;
    margin-bottom: 50px!important;
}

.breeding_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
	font-family: 'Chakra Petch', sans-serif;
}

.breed_heading h2 {
	font-style: normal;
	font-weight: bold;
	font-size: 35px;
	line-height: 40px;
	color: #FFFFFF;
	margin-bottom: 40px;
}


.breed_ready h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #FF0000;
    margin-top: 30px;
}
.breed_ready h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
}
.breed_ready .list_box {
    margin-top: 20px;
}
.breed_ready .list_box ul {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 0;
    padding: 0;
    list-style: none;
}
.breed_ready .list_box ul li {
    padding: 5px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
}
.breed_ready .list_box ul li span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FF0000;
}
.breed_btn {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 30px 0 0;
    padding: 0;
    gap: 30px;
}
.breed_btn a {
    border: 2px solid #ff0000;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
}



.breed_other h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #FF0000;
    margin-top: 30px;
}
.breed_other h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
}
.breed_other .list_box {
    margin-top: 20px;
}
.breed_other .list_box ul {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 0;
    padding: 0;
    list-style: none;
}
.breed_other .list_box ul li {
    padding: 5px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.breed_other .list_box ul li span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FF0000;
}
.other_btn {
    display: grid;
    grid-template-columns: 12fr;
    margin: 30px 0 0;
    padding: 0;
    gap: 30px;
}
.other_btn a {
    border: 2px solid #ff0000;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
}
.breed_box .row {
    row-gap: 40px;
}


.breed_zone_box h2 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    color: #FFFFFF;
    text-shadow: 0px 0px 15px #000000;
    margin: 20px 0 40px;
}

.breed_zone_box ul {
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
}
.breed_zone_box .upload_img {
    text-align: center;
    border: 2px dashed #ffffff;
}
.breed_zone_box .upload_img .image-upload {
    /* padding: 160px 0; */
    height: 30vh;;
	width: 100%;
}
.breed_zone_box .upload_img .image-upload label {
    font-size: 20px;
    margin-top: 20px;
}
.breed_zone_box .upload_img .image-upload>input {
    display: none;
}
.breed_zone_box .breed_ready h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #FF0000;
    margin-top: 30px;
}
.breed_zone_box .breed_ready h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
}
.breed_zone_box .breed_ready .list_box {
    margin-top: 20px;
}
.breed_zone_box .breed_ready .list_box ul {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 0;
    padding: 0;
    list-style: none;
}
.breed_zone_box .breed_ready .list_box ul li {
    padding: 5px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
}
.breed_zone_box .breed_ready .list_box ul li span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FF0000;
}

.breed_zone_box .zone_btn {
    text-align: center;
    margin-top: 40px;
}

.breed_zone_box .zone_btn a {
    background: #ff0000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 40px;
    border: 2px solid #ff0000;
}

.breed_vs {
    width: 120px;
    text-align: center;
    border: 2px solid #ff0000;
    border-radius: 50%;
    height: 120px;
    margin: 170px auto;
}

.breed_vs strong {
    color: #ff0000;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 120px;
}
.breed_cards_heading h2 {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: inherit;
    color: #fff;
    text-shadow: 0px 0px 15px #000000;
    margin: 40px 0 50px;
}
.initiate_content h2 {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: inherit;
    color: #ff0000;
    text-shadow: 0px 0px 15px #000000;
    margin: 40px 0 0;
}
.initiate_content p {
    border-left: 2px solid #ff0000;
    padding: 20px 0 0 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    color: #FFFFFF;
}
.initiate_content a {
    border: 2px solid #ff0000;
    padding: 10px 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
}








.breed_box {
    overflow-y: hidden;
    height: auto;
    overflow-x: hidden;
    padding: 0 50px 0 0;
}

/* width */

.breed_box::-webkit-scrollbar {
	width: 15px;
}

.breed_box {
	scrollbar-width: thin;
	scrollbar-color: #ff0000 rgb(202, 202, 202);
}


/* Track */

.breed_box::-webkit-scrollbar-track {
	border: 1px solid #FFFFFF;
	border-radius: 10px;
}


/* Handle */

.breed_box::-webkit-scrollbar-thumb {
	background: red;
	border-radius: 10px;
}


/* Handle on hover */

.breed_box::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}
@media (max-width: 991px) {
.breed_box {
    padding: 0 10px 0 0;
}
.breed_zone_box .upload_img .image-upload {
    /* padding: 100px 0; */
    height: 30vh;;
	width: 100%;
}
.breed_zone_box .breed_ready .list_box ul li{
    font-weight: 500;
    font-size: 14px;
}
.breed_zone_box .breed_ready .list_box ul li span{
    font-weight: 500;
    font-size: 14px;
}
}
@media (max-width: 767px) {
	.breeding_section {
		text-align: center;
		padding: 80px 0;
	}
	.breeding_section h1 {
		font-size: 40px;
		line-height: 50px;
	}
	.breed_zone_box ul {
		grid-template-columns: 5fr;
	}
	section.breeding_content .row {
		row-gap: 60px;
	}
	.breed_box {
		padding: 0 20px 0 0;
	}
	.breed_cards_heading h2 {
		font-size: 35px;
	}
	.breed_ready .list_box ul li {
		font-size: 14px;
	}
	.breed_ready .list_box ul li span {
		font-size: 14px;
	}
    .breed_other .list_box ul li {
		font-size: 14px;
	}
    .breed_other .list_box ul li span {
		font-size: 14px;
	}
	.breed_vs {
		margin: 70px auto;
	}
	.breed_zone_box .upload_img .image-upload {
		/* padding: 100px 0; */
        height: 30vh;;
	width: 100%;
	}
}
@media (max-width: 420px) {
.card_tab .nav-tabs {
    grid-template-columns: 5fr 5fr;
    
}
}