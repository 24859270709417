footer {
  padding-top: 50px;
  padding-bottom: 30px;
}
.footer_logo {
  text-align: left;
}
.footer_menu ul li a:hover {
  color: #f00;
}

.footer_search ul li a:hover {
  color: #f00;
}
footer .text-danger {
  color: #ff0000 !important;
}
.footer_menu h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #ffffff;
}

.footer_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_menu ul li a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 45px;
  color: #ffffff;
  text-decoration: none;
}

.footer_search a {
  background: #ff0000;
  text-align: center;
  padding: 10px 15px;
  border-radius: 8px;
  display: block;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #ff0000;
}
.footer_search a:hover {
  background: #000000;
}
.subscribe {
  background: #ff0000;
  text-align: center;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  display: block;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #ff0000;
}
.subscribe:hover {
  background: #000000;
}
.footer_search input {
  background: #000;
  border: 2px solid #ec0909;
  border-radius: 8px !important;
}

.footer_search .input-group-btn {
  position: absolute;
  z-index: 99;
  right: 0;
}

.footer_search .input-group-btn i {
  color: #fff;
}

.footer_search {
  text-align: center;
}

.footer_search ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}

.footer_search ul li {
  display: inline-block;
  padding: 0 30px 0 0;
}

.footer_search ul li a {
  background: transparent;
  padding: 0;
  font-size: 28px;
  color: #fff;
  border: none;
}

.copy_right p {
  text-align: left;
  font-size: 18px;
  color: #fff;
  margin-top: 40px;
}

.copy_right {
  margin-top: 30px;
}
.footer_search a:hover {
  color: #fff;
}
@media (min-width: 1200px) {
  .footer_search ul li {
    padding: 0 10px 0 10px;
  }
  .footer_search ul {
    text-align: center;
  }
  .copy_right p {
    text-align: left;
    font-size: 15px;
  }
}
@media (max-width: 1199px) {
  .footer_menu ul li a {
    font-size: 16px;
    line-height: 35px;
  }
  .footer_search ul li {
    display: inline-block;
    padding: 0 10px 0 0;
  }
}

@media (max-width: 767px) {
  .footer_menu {
    margin: 15px 0;
  }
  .footer_search ul li {
    display: inline-block;
    padding: 0 15px 0 0;
  }
  .footer_search ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
}
