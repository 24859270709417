.Ranks {
	background-image: url("../../../../../assets/banner/more_ranks-01.png"); 
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.Ranks {
	text-align: center;
	padding: 120px 0;
}

.Ranks h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #FFFFFF;
}

.rank .text-start{
	align-items: center;
}
.nav_menu ul {
	padding: 0;
	margin: 0;
	text-align: center;
}

.nav_menu ul li {
	list-style: none;
	display: inline-block;
}

.nav_menu ul li a {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 31px;
	text-align: center;
	color: #FFFFFF;
}

.nav_menu ul li a:hover {
	color: #FF0000;
}

.nav_menu ul li a.active {
	color: #ff0000;
}


.board_text_section ul {
    padding: 0;
    margin: 0 0 30px 0;
    display: grid;
    grid-template-columns: 6fr 6fr;
}

.board_text_section ul li {
	list-style: none;
	align-items: center;
}

.board_text_section h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 50px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 50px;
}

.rank {
	display: flex;
	gap: 15px;
	align-content: center;
	vertical-align: middle;
}

.rank .rank_img {
	/* border: 1px solid #fff; */
	border-radius: 50%;
	width: 90px;
	/* height: 90px; */
	/* padding: 15px; */
}
.rank .rank_img img{
	width: 70px !important;
	margin: 0 auto;
}
.user_name {
	width: 100%;
}
.user_name span {
    color: #ffffff;
    font-size: 20px;
}

.user_name h5 {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.info_user span {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: white;
    margin: 0;
    line-height: 20px;
	word-wrap: break-word;
}

.info_user strong {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    color: #FF0000;
}



@media (max-width: 767px) {
	.Ranks {
		text-align: center;
		padding: 80px 0;
	}
	.Ranks h1 {
		font-size: 43px;
		line-height: 50px;
	}
	.Ranks img{
		width: 70px!important;
	}
}