/* Button */
.main-button{
    background: #ff0000;
    padding: 10px 15px;
    border-radius: 10px;
    display: block;
    color: #fff;
    text-decoration: none;
    border: 1px solid #ff0000;
    text-transform: uppercase;
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
}
.main-button:hover {
    background: #000000;
}

/* Arrow */
.arrow-icon{
    width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 26px solid #ff0000;
}

.rotate{
    transform: rotate(180deg);
}

/* Form */
.main-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    padding-bottom: 2rem;
    /* border-bottom: 1px solid #FFFFFF; */
}


/* Title form  */
.title-form-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    align-items: center;
}

.title-form{
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
}
.text-message{
    display: none;
    background: #000000;
    border-radius: 20px;
    font-family: 'Chakra Petch';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 1rem;

}
.svg-icon:hover + .text-message{display: flex;}
.svg-icon{
    margin-bottom: 1rem;
    margin-right: 1rem;

}

/* Input form  */
.input-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    margin-right: 2rem;
}

.input-label{
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 0.5rem;

}
.input-basic{
    width: 230px;
    background: transparent;
    font-family: 'Orbitron';
    border-radius: 10px;
    border: 1px solid #7E7E7E;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 15px;
}

/* loading */
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    z-index: 10;
  }
  .spinner-container{
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    justify-content: center;
    align-items: center;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid #ff0000;
    border-left: 4px solid transparent;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  /* Modal */
  .modalRes {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
     z-index: 1;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(31, 31, 31, 0.9); /* Black w/ opacity */
  }
  .modalRes-content {
    color: #FFF;
    display: flex;
    flex-direction: column;
    background-color: #000000;
    z-index: 2;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  .close-modalRes {
    align-self: end;
    color: #ff0000;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .close-modalRes:hover {
    color: #aaaaaa;
    text-decoration: none;
    cursor: pointer;
  }
  