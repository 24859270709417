.privacy {
  text-align: justify;
  color: white;
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
}
.privacy h3,
.privacy h4,
.privacy h5 {
  text-align: center;
}
