.Page_contact_section {
    background-image: url("../Contact/Sections/Images/about-tesseract.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
  }
  .Page_contact_section {
      text-align: center;
      padding: 120px 0;
  }
  
  .Page_contact_section h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 73px;
      line-height: 92px;
      text-align: center;
      color: #FF0000;
  }
  .contact_info .col-md-4 { 
    display: table;
}
.contact_box {
    text-align: center;
    border: 2px solid #FF0000;
    padding: 30px 20px;
    border-radius: 8px;
    display: table-cell;
}

.contact_box .icon i {
    font-size: 40px;
    color: #ff0000;
}

.contact_box .contact_text p {
    margin: 0;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 28px;
}

.contact_box .contact_text a {
    margin: 0;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 28px;
    text-decoration: none;
}

.contact_box .icon {
    margin-bottom: 20px;
}
.form_contact input {
    background: transparent;
    padding: 12px 20px;
    border-radius: 10px;
    border: 2px solid #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 40px;
    opacity: 0.5;
}
.form_contact textarea {
    background: transparent;
    border-radius: 10px;
    border: 2px solid #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 40px;
    opacity: 0.5;
}
.form_contact label {
    color: #FFFFFF;
    text-transform: capitalize;
    font-size: 18px;
    margin-bottom: 15px;
}
.form_contact form button.form-control.btn.btn-primary.rounded.submit:hover{
    background-color: #000000;
}
.form_contact form button.form-control.btn.btn-primary.rounded.submit{
    background: #FF0000;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 10px 0;
    border: 2px solid #ff0000;
}
.form_contact h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 40px;
    text-align: left;
    color: #FF0000;
    margin-bottom: 30px;
}
.form_contact form .form-control:focus {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 0%);
}



  @media (max-width: 767px) {
      .Page_contact_section {
          text-align: center;
          padding: 80px 0;
      }
      .Page_contact_section h1 {
          font-size: 43px;
          line-height: 50px;
      }
      }