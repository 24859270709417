.SellSection,
.sell_section_AUCTION,
.sell_section_MARKETPLACE {
  background-image: url("../../assets/banner/Buys_sell-cards.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 480px; */
}

.SellSection,
.sell_section_AUCTION,
.sell_section_MARKETPLACE  {
  text-align: center;
  padding: 120px 0;
}

.SellSection h1,
.sell_section_AUCTION h1,
.sell_section_MARKETPLACE  h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
}
.confirm-button{
  border: 2px solid #EC0909;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  padding: 8px 40px;
  background-color: black;
}
.confirm-button:hover{
  background-color: red;
}
.purchase_box a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  border: 2px solid #ff0000;
  padding: 10px 0;
  border-radius: 8px;
}

.purchase_box .market {
  background-color: #ff0000;
  margin-bottom: 30px;
}

.purchase_box .market:hover {
  background-color: transparent;
}

.sell_tab .nav-tabs {
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  border: none;
  margin: 0 0 50px 0;
  gap: 20px;
}

.sell_tab .nav-tabs li {
  list-style: none;
  display: inline-block;
}

.sell_tab .nav-tabs .nav-link.active,
.sell_tab .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.sell_tab .nav-tabs .nav-item .nav-link {
  width: 100%;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
}

.sell_heading h2 {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 30px;
}

.sell_tab h3 {
  font-size: 35px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.sell_box h4 {
  font-size: 35px;
  font-weight: 700;
  margin: 20px 0;
  color: #ffffff;
}

.sell_list_box ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  padding: 0;
  margin: 0;
  gap: 15px;
}

.sell_list_box ul li {
  color: #ffffff;
  font-size: 15px;
  list-style: none;
  line-height: 24px;
}

.sell_list_box ul li span {
  font-weight: 700;
}

.sell_btn ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  list-style: none;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.sell_btn ul li a {
  color: #ffffff;
  width: 100%;
  border: 2px solid #ff0000;
  font-size: 18px;
  padding: 10px 5px;
}

.sell_scroll_link {
  margin-bottom: 40px;
}

.sell_scroll_link ul {
  display: flex;
  gap: 20px;
  list-style: none;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.sell_scroll_link ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.sell_scroll_link ul li a:hover {
  color: #ff0000;
}

.purchase_details_card h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 30px;
}

.purchase_details_card p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 31px;
  color: #ffffff;
}

.purchase_details_card ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin: 0;
  padding: 0 60px;
}

.purchase_details_card ul li {
  list-style: none;
  padding: 10px 25px;
}

.purchase_details_card ul li img {
  width: 90px;
}

.purchase_details_card ul li p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}

.purchase_details_card ul li p span {
  color: #ff0000;
}

.features_status_purchase h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 30px;
}

.features_status_purchase ul {
  padding: 10px 0;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

.features_status_purchase ul li {
  list-style: none;
  padding: 15px 0;
  color: #fff;
  font-size: 18px;
}

.features_status_purchase ul li span {
  color: #ff0000;
  font-size: 18px;
}

.features_status_purchase ul li strong {
  color: #fff;
  font-size: 18px;
}

.features_status_purchase ul li hr {
  color: #ff0000;
  opacity: 1;
  width: 50px;
  margin: 10px auto;
}

.sell_current_status ul {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 20px 10px;
  list-style: none;
  border: 1px solid #ff0000;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
}

.sell_current_status ul li span {
  font-size: 18px;
  color: #fff;
  line-height: 28px;
}

.sell_current_status ul li strong {
  font-size: 27px;
  color: #fff;
}

.sell_current_status ul li a {
  background: #ff0000;
  color: #fff;
  padding: 20px 50px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 8px;
  border: 2px solid #ff0000;
  display: inline-block;
}

.sell_current_status ul li a:hover {
  background: transparent;
  color: #ff0000;
}

.sell_card_heading h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 50px;
  color: #ffffff;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.sell_card_succ_info h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #ff0000;
}

.sell_card_succ_info h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
}

.sell_card_list_box {
  margin-top: 20px;
}

.sell_card_list_box ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sell_card_list_box ul li {
  padding: 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.sell_card_list_box ul li span {
  color: #ff0000;
}

.sell_card_succ_text h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 50px;
  color: #ff0000;
}

.sell_card_succ_text {
  margin-top: 40px;
}

.purchase_succ_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  border-left: 1px solid #ff0000;
  padding-left: 15px;
}

.sell_btn a {
  border: 2px solid #ff0000;
  padding: 10px 40px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  width: 100%;
}
.fixed a {
  background-color: #ff0000;
}
.fixed a:hover {
  background-color: transparent;
}
.purchase_succ_text .form-floating label {
  font-style: italic;
  color: #fff;
  font-size: 16px;
  padding: 0;
}

.purchase_succ_text .form-floating textarea {
  border-bottom: 2px solid #434343;
  border-radius: 0;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0;
  color: #fff;
  height: 100px;
}
.sell_price {
  border-left: 1px solid #ff0000;
  padding-left: 15px;
  gap: 20px;
}
.sell_card_succ_text p {
  border-left: 1px solid #ff0000;
  padding-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}
.price {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 6fr 6fr;
  gap: 20px;
  padding-top: 20px;
}
.price span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.price input {
  background: transparent;
  border: 1px solid #ff0000;
  font-size: 24px;
  padding: 0 10px !important;
  text-align: right;
  color: white;
}
.price_table ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  list-style: none;
  padding: 20px;
  border: 1px solid #ff0000;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  margin-top: 30px;
}

.price_table ul li {
  font-size: 18px;
  color: #fff;
}
.sell_card_btn {
  margin: 30px 0;
}
.sell_card_btn ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  list-style: none;
  gap: 15px;
  padding: 0;
  margin: 0;
}
.sell_card_btn ul li a {
  color: #ffffff;
  width: 100%;
  border: 2px solid #ff0000;
  font-size: 18px;
  padding: 10px 5px;
}
.purchase_btn button {
  border: 2px solid #ff0000;
  padding: 10px 40px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
.price input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(99, 99, 99) !important;
}

.price input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(99, 99, 99) !important;
}

.price input::placeholder {
  color: rgb(99, 99, 99) !important;
}
.price strong.text-danger {
  color: #ff0000 !important;
}
.price_table strong.text-danger {
  color: #ff0000 !important;
}
.sell_list_box .text-danger {
  color: #ff0000 !important;
}
.sell_current_status ul li strong.text-danger {
  color: #ff0000 !important;
}
.sell_box {
  overflow-y: hidden;
  height: auto;
  overflow-x: hidden;
  padding: 0 50px 0 0;
}

/* width */

.sell_box::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.sell_box::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.sell_box::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */

.sell_box::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

@media (max-width: 991px) {
  .purchase_details_card ul {
    margin: 0;
    padding: 0 0;
  }
  .purchase_details_card h2 {
    font-size: 38px;
  }

  .features_status_purchase ul {
    grid-template-columns: 5fr 5fr 5fr;
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .SellSection {
    text-align: center;
    padding: 80px 0;
  }
  .SellSection h1 {
    font-size: 43px;
    line-height: 50px;
  }
  .sell_current_status ul {
    grid-template-columns: 3fr;
  }
  .features_status_purchase ul {
    grid-template-columns: 5fr 5fr;
    gap: 10px;
  }
  .features_status_purchase h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .sell_tab .nav-tabs {
    grid-template-columns: 5fr 5fr;
  }
  .sell_box {
    padding: 0 20px 0 0;
  }
}
@media (max-width: 420px) {
  .SellSection h1 {
    font-size: 33px;
    line-height: 50px;
  }
  .sell_card_btn ul {
    grid-template-columns: 6fr;
  }
  .sell_box h4 {
    font-size: 25px;
  }
  .sell_card_heading h2 {
    font-size: 35px;
  }
  .sell_card_succ_text h3 {
    font-size: 30px;
  }
}
