.promo-code-banner {
  background-image: url(../Images/more_banner.png);
  height: 480px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.promo-code-banner {
	text-align: center;
	padding: 120px 0;
	margin-bottom: 50px!important;
}

.promo-code-banner h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
	font-family: 'Chakra Petch', sans-serif;
	text-transform: capitalize;
}


@media screen and (max-width:650px) {
  .promo-code-banner h1 {
    font-size: 36px;
    word-wrap: break-word;
  }
}