.section {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
}
.section .main {
  width: 65%;
}
.section .sub {
  width: 35%;
  border-left: 1px solid #fff;
  /* margin: 20px; */
  padding-left: 20px;
}
.section .sub h5 {
  color: #fff;
}
.section .sub span {
  font-style: italic;
  word-wrap: break-word;
}
.main_container {
  display: flex;
  flex-direction: column;
  margin: 4rem 5rem 4rem 6rem;
  font-family: "Orbitron", sans-serif !important;
  flex-basis: auto;
}

.nav_bar {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 3rem;
}

.container-box-scroll {
  display: block;
  height: auto;
  padding-right: 2rem;
}
.container-box-scroll::-webkit-scrollbar {
  width: 15px;
}
.container-box-scroll::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.container-box-scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.container-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
}

.nav-btn {
  display: inline-block;
  color: #fff !important;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #ff0000;
  padding: 10px 15px;
  margin-bottom: 1rem;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.active {
  background: #ff0000;
  color: #fff !important;
  border-color: #ff0000 !important;
}

/* section components style */

.a-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid #ff0000;
}

.a-first-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.a-second-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.a-title {
  color: #ff0000;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-holder {
  text-transform: uppercase;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  /* border: 1px solid #FF0000; */
  border-radius: 10px;
  padding: 10px 15px;
}

.input-content-start {
  display: grid;
  grid-template-columns: auto auto;
  flex-wrap: wrap;
  align-items: center;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
}

.drop-down-close {
  display: none;
}

.drop-down-open {
  display: flex;
  flex-direction: column;
}

/* Modal */
.modal-Blockchain-log {
  display: flex;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 90%;
  overflow: scroll;
  margin: 1rem 2rem 2rem 1rem;
  padding: 1rem;
}

.admin-container p {
  color: white !important;
  opacity: 0.7 !important;
}

@media screen and (max-width:950px) {
  .section {
    flex-direction: column;
  }
}
