.build_army ul li a:hover {
  background: #ff0000;
  color: #fff;
}

.banner_section {
  background-image: url("./Sections/Images/merged-01.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 420px;
  position: relative;
}
.banner_section .container{
  margin: 0 auto!important;
}
.about_section, .about_section .container {
  margin: 30px auto!important;
}
.banner_text {
  text-align: center;
  /* padding: 150px 0; */
}
.banner_section .blacknet{
  position: absolute;
  right: 50%;
  top: 80px;
}
.banner_section .alice{
  position: absolute;
  left: 50%;
  top: 80px;
}
.banner_section .humanity{
  position: relative;
  width: 100%;
  top: 240px;
}
.banner_text img{
  width: 150px;
 opacity: .7;
}
.banner_text img:hover{
  opacity: 1;
 
}

/* .banner_text h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ff0000;
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
} */

.play_text_box p,
.work_text_box p {
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 30px;
}

.play_text_box a {
  border: 2px solid #ff0000;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 40px;
}

.synopsis_slider h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 63px;
  line-height: 79px;
  color: #ff0000;
  margin-bottom: 40px;
  text-align: left;
}

.synopsis_slider p {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: left;
}

.synopsis_slider a {
  border: 2px solid #ff0000;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 40px;
  text-align: left;
  float: left;
}

.synopsis_slider .slick-dots li button {
  width: 15px;
  height: 15px;
  color: #ff0000;
  border: 1px solid #ff0000;
  outline: none;
  background: #ff000000;
  border-radius: 50%;
}

.synopsis_slider .slick-dots {
  bottom: -50px;
}

.synopsis_slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: transparent;
  background: #ff0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.profile_slider h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 79px;
  color: #ff0000;
  margin-bottom: 20px;
  text-align: left;
}

.profile_slider p {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #ff0000;
  text-align: left;
  text-transform: capitalize;
}

.profile_slider p span {
  font-size: 42px;
}

.profile_slider a {
  border: 2px solid #ff0000;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 40px;
  text-align: left;
  float: left;
}

.profile_slider .slick-dots li button {
  width: 15px;
  height: 15px;
  color: #ff0000;
  border: 1px solid #ff0000;
  outline: none;
  background: #ff000000;
  border-radius: 50%;
}

.profile_slider .slick-dots {
  bottom: -50px;
}

.profile_slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: transparent;
  background: #ff0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.profile_slider img {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: 0 auto;
  /* border: 2px dashed #fff; */
}

.profile_slider h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #ffffff;
  opacity: 0.5;
  text-align: left;
}

.profile_slider h3 {
  font-weight: 500;
  font-size: 48px;
  line-height: 62px;
  color: #ffffff;
  text-align: left;
}

.play_section {
  background-image: url("./Sections/Images/how_to_play.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.play_section {
  margin-top: 120px;
}

.play_text {
  text-align: center;
  padding: 120px 0;
}

.play_text h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ff0000;
}

.build_section h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 62px;
  line-height: 78px;
  color: #ff0000;
  margin-bottom: 30px;
  text-align: left;
}

.build_section p {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  text-align: left;
}

.build_army ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.build_army ul li {
  list-style: none;
}

.build_army ul li a {
  border: 2px solid #ec0909;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  padding: 10px 50px;
  text-decoration: none;
}

.build_army {
  display: block;
  margin-top: 40px;
  text-align: left;
}

.build_img h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}

.build_img p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

.build_img a {
  border: 2px solid #ec0909;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 0;
  display: inline-block;
}

.armybuild_slider .slick-dots li button {
  width: 15px;
  height: 15px;
  color: #ff0000;
  border: 1px solid #ff0000;
  outline: none;
  background: #ff000000;
  border-radius: 50%;
}

.armybuild_slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: transparent;
  background: #ff0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.armybuild_slider .slick-dots {
  bottom: -50px;
}

.join_section {
  background-image: url("./Sections/Images/join_community.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.join_section {
  text-align: center;
  padding: 120px 0;
  margin-top: 120px;
}

.join_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ff0000;
}

.work_text_box a.work_byn.btn {
  border: 2px solid #ff0000;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 40px;
}
.work_text_box a:hover{
  color: #ff0000;
}
.work_text_box ul {
  padding: 0;
  list-style: none;
}

.work_text_box ul li {
  display: inline-block;
  padding: 0 20px;
}

.work_text_box ul li a {
  font-size: 30px;
  color: #ffffff;
}

@media (max-width: 1199px) {
  .play_text_box p,
  .work_text_box p {
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
  }
  .play_text_box a {
    font-size: 20px;
  }
  .synopsis_slider h2,
  .profile_slider h2,
  .build_section h2 {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 20px;
    text-align: left;
  }
  .synopsis_slider p,
  .profile_slider p,
  .build_section p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: left;
  }
  .synopsis_slider a,
  .profile_slider a {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 30px;
    text-align: left;
    float: left;
  }
  .profile_slider h6 {
    font-size: 30px;
  }
  .profile_slider h3 {
    font-size: 28px;
    line-height: 30px;
  }
  .synopsis_slider a {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 30px;
    text-align: left;
    float: left;
  }
  .play_text h1,
  .join_section h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .build_army ul li a {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 30px;
    text-align: left;
    float: left;
  }
  .work_text_box a.work_byn.btn {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 30px;
  }
}

@media (max-width: 991px) {
  .synopsis_slider h2,
  .build_section h2,
  .profile_slider h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .synopsis_slider p,
  .build_section p,
  .profile_slider p {
    font-size: 20px;
    line-height: 33px;
  }
  .profile_slider p span {
    font-size: 32px;
  }
  .build_army ul li a {
    font-size: 16px;
    line-height: 30px;
    padding: 10px 20px;
  }
  .work_text_box p,
  .play_text_box p {
    font-size: 18px;
    line-height: 33px;
  }
}

@media (max-width: 767px) {
  .banner_text {
    text-align: center;
    /* padding: 70px 0; */
  }
  /* .banner_text h1 {
    font-size: 63px;
    line-height: 60px;
  } */
  .synopsis_slider h2,
  .profile_slider h2,
  .build_section h2 {
    margin-top: 30px;
  }
  .join_section h1,
  .play_text h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 480px) {
  .build_army ul {
    grid-template-columns: 2fr;
    row-gap: 20px;
  }
  .work_text_box ul li {
    display: inline-block;
    padding: 0 10px;
  }
}
