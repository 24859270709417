.profile_user_section {
  background-image: url("../../../../assets/banner/User-profile.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile_user_section {
  text-align: center;
  padding: 120px 0;
}

.profile_user_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
}

.user_profile_image {
  position: relative;
}

.user_profile_image img {
  width: 170px;
  height: 170px;
  background: #000;
  /* border: 2px dashed #939292; */
  border-radius: 50%;
  /* padding: 20px; */
  position: absolute;
  top: -70px;
}

.profile_info {
  margin-top: 30px;
}

.profile_info h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
  color: #ff0000;
}

.profile_info p {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  word-break: break-all;
}

.user_tab .nav-tabs {
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
  border: none;
  margin: 0 0 50px 0;
  gap: 20px;
  list-style: none;
}

.user_tab .nav-tabs .nav-link.active,
.user_tab .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #ff0000 #ff0000 #ff0000;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
}
.user_tab .nav-tabs .nav-link:hover,
.user_tab .nav-tabs .nav-link:focus {
  border-color: #ff0000;
  isolation: isolate;
}
.user_tab ul li {
  margin: 0 12px;
}
.user_tab .nav-tabs .nav-link {
  margin-bottom: 0;
  background: none;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  width: 100%;
}
.nav-links {
  margin-bottom: 0;
  background: none;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  /* width: 100%; */
}
.card_link {
  margin-top: 30px;
}

.profile_tab_content {
  margin-top: 30px;
  margin-bottom: 30px;
}

.profile_tab_content h2 {
  margin-left: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  color: #ff0000;
  margin-bottom: 40px;
}

.profile_card_info {
  margin-bottom: 40px;
}

.profile_card_info h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #ffffff;
  margin-top: 30px;
}

.profile_card_info .card_info_text {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 20px;
}

.card_info_text .info_content span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  color: #ffffff;
}
.card_info_text .info_content strong {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.rank strong {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
}
.rank span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  color: #ffffff;
}
.card_link ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  /* margin-top: 25px; */
}

.card_link ul li {
  list-style: none;
  width: 100%;
  /* margin: 0 20px 0; */
}

.card_link ul li a {
  border: 2px solid #ff0000;
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 13px;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
}

.card_link ul li a:hover {
  background-color: #ff0000;
}
.profile_type ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.profile_type ul li {
  list-style: none;
  display: inline-block;
  padding: 0 20px;
}

.profile_type ul li a {
  color: #fff;
  text-decoration: none;
}
.profile_type ul li a:hover {
  color: #ff0000;
  text-decoration: none;
}

.profile_type {
  margin-bottom: 40px;
}

.profile_tab_content {
  overflow-y: hidden;
  height: auto;
  overflow-x: hidden;
  padding: 0 50px 0 0;
}

/* width */

.profile_tab_content::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.profile_tab_content::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

/* Handle */

.profile_tab_content::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

@media (max-width: 1199px) {
  .user_profile_image img {
    width: 100px;
    height: 100px;
    top: -40px;
  }
  .card_link ul li a {
    padding: 10px 10px;
    font-size: 13px;
    white-space: normal;
    word-wrap: break-word;
  }
  .profile_tab_content {
    padding: 0 10px 0 0;
  }
  .profile_type ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .profile_type ul li {
    list-style: none;
    display: inline-block;
    padding: 5px 20px;
  }
  .profile_card_info h3 {
    font-size: 25px;
  }
}
@media (max-width: 991px) {
  .card_link ul li a {
    padding: 10px 10px;
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
  }

  .user_tab .nav-tabs {
    display: grid;
    grid-template-columns: 5fr 5fr 5fr;
    border: none;
    margin: 0 0 50px 0;
    gap: 20px;
  }
  .card_link ul {
  }
  .profile_card_info h3 {
    font-size: 23px;
    line-height: 30px;
    margin-top: 30px;
  }
  .card_info_text .info_content span {
    font-size: 14px;
  }
  .card_info_text .info_content strong {
    font-size: 14px;
  }

  .rank span,
  .rank strong {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .card_link ul li a {
    padding: 10px 10px;
    font-size: 10px;
    white-space: normal;
    word-wrap: break-word;
  }

  .profile_card_info h3 {
    font-size: 23px;
    line-height: 30px;
    margin-top: 30px;
  }
  .card_info_text .info_content span {
    font-size: 14px;
  }
  .card_info_text .info_content strong {
    font-size: 14px;
  }
  .rank span,
  .rank strong {
    font-size: 14px;
  }
  .profile_user_section {
    text-align: center;
    padding: 80px 0;
  }
  .profile_user_section h1 {
    font-size: 43px;
    line-height: 50px;
  }
  .profile_tab_content h2 {
    font-size: 35px;
    line-height: 30px;
  }
  .profile_type ul li {
    list-style: none;
    display: inline-block;
    padding: 6px 5px;
  }
}
@media (max-width: 650px) {
  .user_tab .nav-tabs {
    display: grid;
    grid-template-columns: 5fr 5fr;
  }
}
@media (max-width: 480px) {
  .card_link ul li a {
    padding: 10px 10px;
    font-size: 10px;
    white-space: normal;
    word-wrap: break-word;
  }
  .card_link ul {
  }
  .user_profile_image img {
    width: 100px;
    height: 100px;
    top: 0;
    position: relative;
  }
  .profile_info {
    margin-top: 0;
  }
}
