html {
	scroll-behavior: smooth;
  }


.Page_card_section {
	background-image: url("../Images/purchase_banner.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}


.Page_card_section {
	text-align: center;
	padding: 120px 0;
}

.Page_card_section h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 73px;
	line-height: 92px;
	text-align: center;
	color: #ffffff;
}
.card_tab .nav-tabs {
    display: grid;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
    border: none;
    margin: 0 0 50px 0;
    gap: 20px;
}


.card_tab .nav-tabs .nav-item .nav-link {
    width: 100%;
    background: transparent;
    border: 1px solid #ff0000;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
}
.card_tab .nav-tabs .nav-link.active, .card_tab .nav-tabs .nav-item.show .nav-link {
    color: #ffffff;
    background-color: #ff0000;
    border-color: #ff0000
	;
}

.card_tab h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: left;
    color: #FF0000;
}

.card_tab p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 50px;
}

.custom_tab_text h3 {
    font-size: 28px;
    color: #fff;
    text-align: left;
	font-weight: bold;
}

.custom_tab_text p {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
}
.card_tab .row {
    row-gap: 40px;
}
.card_tab .row {
    row-gap: 40px;
}

.scroll_link {
    margin-bottom: 40px;
}

.scroll_link ul {
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    gap: 15px;
    list-style: none;
    justify-content: space-between;
    flex-wrap: wrap;
}

.scroll_link ul li {
	list-style: none;
	display: inline-block;
	padding: 0;
}


.scroll_link ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    text-transform: capitalize;
}

.scroll_link ul li a:hover {
    color: #ff0000;
}

.content_box {
    overflow-y: hidden;
    height: auto;
    overflow-x: hidden;
    padding: 0 50px 0 0;
}
.custom_tab_text a:not(#getting-started-link) {
    border: 2px solid #ff0000;
    padding: 8px 30px;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
}
.custom_tab_text a:not(#getting-started-link):hover {
    color: #ffffff;
}
.content_box.cost_fee_tab {
    overflow: hidden;
    height: auto;
}

.custom_tab_text.cost_fee p {
    margin: 0 0 5px 0;
}

.custom_tab_text.cost_fee hr {
    color: #ff0000;
    opacity: 1;
}
/* width */

.content_box::-webkit-scrollbar {
	width: 15px;
}


/* Track */

.content_box::-webkit-scrollbar-track {
	border: 1px solid #FFFFFF;
	border-radius: 10px;
}


/* Handle */

.content_box::-webkit-scrollbar-thumb {
	background: red;
	border-radius: 10px;
}


/* Handle on hover */

.content_box::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

.card_box h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    /* margin-top: 30px; */
}

.card_info {
    display: grid;
    grid-template-columns: 2fr 2fr;
      align-items: center;
}

.card_type p, .card_rank p {
    text-align: left;
    margin: 0;
    text-align: left;
    margin: 0;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    font-weight: normal;
}

.card_type p strong, .card_type p span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.card_type p span {
    font-weight: normal;
}


.card_rank p strong, .card_rank p span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.card_rank p span {
    font-weight: normal;
}
.battle_box {
    position: relative;
}

.battle_box ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
}

.battle_box ul li {
    display: inline-block;
}

.battle_proess {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -30px;
    top: auto;
}
.battle_proess h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;
}

.battle_proess h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #FF0000;
}
.battle_proess .progress {
    max-width: 230px;
    margin: 0 auto;
    background-color: transparent;
    border: 1px solid #f00;
    border-radius: 6px;
}

.battle_proess .progress .progress-bar {background-color: #f00;}


.cards_menu_list ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  margin: 0 0 50px 0;
}
.cards_menu_list ul li{
   flex-basis: 20%; margin:0 10px;
}
.cards_menu_list .nav_menu ul li {
    list-style: none;
    display: inline-block;
}
.cards_menu_list ul .nav-item .nav-link {
    width: 100%;
    background: transparent;
    border: 1px solid #ff0000;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
}
.cards_menu_list ul .nav-link.active, .cards_menu_list ul .nav-item.show .nav-link {
    color: #ffffff;
    background-color: #ff0000;
    border-color: #ff0000;
}
.content_box h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: normal;
    text-align: left;
    color: #FF0000;
    margin-bottom: 30px;
}
.card_box {
    margin-bottom: 40px;
}
@media (max-width: 1199px) {
.card_tab .nav-tabs .nav-item .nav-link {
     font-size: 14px;
} 
}
@media (max-width: 1024px) {
.card_tab .nav-tabs {

    grid-template-columns: 5fr 5fr 5fr;
    
}
}
@media (max-width: 767px) {
	.Page_card_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_card_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
    .Page_card_section .row {
        row-gap: 50px;
    }
    .content_box {
        padding: 0 20px 0 0;
    }
	}

@media (max-width: 520px) {
.card_tab .nav-tabs {
    grid-template-columns: 5fr 5fr;
    gap: 10px;
}
.card_tab .nav-tabs .nav-item .nav-link {
    font-size: 12px;
}
}
