.war-input{
    width: 45%;
    height: 50px!important;
    background: transparent;
    border-radius: 10px;
    border: 2px solid #fff;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 15px !important;
    opacity: 0.5;
    padding: 15px 15px;
    border-radius: 10px;
}
.war-button{
    background: #ff0000;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    padding: 10px 0;
    border: 2px solid #ff0000;
    width: 45%;
    height: 50px!important;
    margin: 0 10px; 
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: black;
  color: #666666;
}