.Page_tesseract_section {
    background-image: url("./Sections/images/tesseract_banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 480px;
  }
  .Page_tesseract_section {
      text-align: center;
      padding: 120px 0;
  }
  .anchor{
    opacity: .7;
  }
  .anchor:hover{
    opacity: 1;
  }
  .Page_tesseract_section h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 73px;
      line-height: 92px;
      text-align: center;
      color: #ffffff;
  }

  .tesserac_text_box h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
}

.tesserac_text_box p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}


.tesserac_text_box a.tesserac {
    border: 2px solid #EC0909;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 8px 40px;
}
.roadmap_heading h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
    margin-bottom: 30px;
}

.roadmap_box {
    overflow-y: hidden;
    height: auto;
    padding: 0 20px 0 0;
   
}

/* width */

.roadmap_box::-webkit-scrollbar {
	width: 15px;
}
.roadmap_box {
    scrollbar-width: thin;
      scrollbar-color: #ff0000 rgb(202, 202, 202);
      
    }

/* Track */

.roadmap_box::-webkit-scrollbar-track {
	border: 1px solid #FFFFFF;
	border-radius: 10px;
}


/* Handle */

.roadmap_box::-webkit-scrollbar-thumb {
	background: red;
	border-radius: 10px;
}


/* Handle on hover */

.roadmap_box::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}
@media (max-width: 767px) {
	/* .Page_tesseract_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_tesseract_section h1 {
		font-size: 43px;
		line-height: 50px;
	} */
	}