.promo-code h1 {
  margin: 60px 0 30px;
  text-align: center;
  font-style: normal;
  font-size: 45px;
  line-height: 50px;
  color: #ff0000;
  text-transform: uppercase;
}

.promo-code-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.promo-code-button button {
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: white;
  margin: 10px;
  height: 50px;
  padding: 8px 30px;
  font-family: "Orbitron", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 2px solid #ff0000;
}
.promo-code-button .active {
  background-color: #ff0000;
}
.promo-code-button button:hover {
  background-color: #ff0000;
}

.promo-list {
  display: flex;
  /* width: 90%; */
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 650px) {
  .promo-list {
    flex-direction: column;
  }
}
