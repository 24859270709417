.warzone_section {
  background-image: url("../../../../assets/banner/War-landing-page.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.warzone_section {
  text-align: center;
  padding: 120px 0;
}

.warzone_section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 92px;
  text-align: center;
  color: #ffffff;
  font-family: "Chakra Petch", sans-serif;
}

.battlefield_content ul {
  display: grid;
  grid-template-columns: 5fr 2fr 5fr;
  margin: 30px 0 30px 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.battlefield_content ul li {
  text-align: center;
}

.battlefield_content ul li img {
  width: 180px;
  height: 180px;
}

.battlefield_info h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: #ff0000;
}

.battlefield_info {
  margin-top: 30px;
}

.field_info {
  border-left: 1px solid #ff0000;
  margin: 0 auto;
  max-width: 120px;
  padding-left: 15px;
}

.field_info p {
  margin: 0;
  text-align: left;
  color: #fff;
  font-size: 16px;
}

.field_info p span {
  font-size: 25px;
}

.field_btn {
  text-align: center;
  margin-bottom: 40px;
}

.field_btn a {
  border: 2px solid #ff0000;
  color: #fff;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 600;
}
.war-confirm-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}
@media (max-width: 480px) {
  .war-confirm-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 950px) and (min-width: 481px) {
  .war-confirm-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1300px) and(min-width:951px) {
  .war-confirm-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.war_vs {
  width: 150px;
  text-align: center;
  border: 2px solid #ff0000;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto;
}

.war_vs strong {
  color: #ff0000;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 150px;
}
.battlefield_content h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}

.warzone_field {
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgb(202, 202, 202);
}

.create_zone .form-check {
  display: block;
  margin-bottom: 15px;
  padding: 0;
}

.create_zone .form-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.create_zone .form-check label {
  position: relative;
  cursor: pointer;
  color: #ff0000;
}

.create_zone .form-check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ff0000;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 13px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}
.create_zone form label {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.create_zone form {
  text-align: center;
}
.create_zone .form-check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 11px;
  width: 8px;
  height: 18px;
  border: solid #ff0000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.create_zone ul {
  display: grid;
  grid-template-columns: 5fr 2fr 5fr;
  margin: 30px 0 30px 0;
  padding: 0;
  list-style: none;
  align-items: center;
  text-align: center;
}
.create_button {
  text-align: center;
  margin-top: 40px;
}

.create_button button {
  border: 2px solid #ff0000;
  color: #ffffff;
  font-weight: 700;
  padding: 10px 30px;
  font-size: 20px;
}

.create_battlefield h2 {
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
}

.create_battlefield p {
  color: #fff;
  font-size: 18px;
}

.create_battlefield p i {
  color: #ff0000;
  padding-right: 10px;
}
.create_zone ul li img {
  width: 150px;
}
.create_zone .war_vs strong {
  color: #ff0000;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 120px;
}
.create_zone .war_vs {
  width: 120px;
  text-align: center;
  border: 2px solid #ff0000;
  border-radius: 50%;
  height: 120px;
  margin: 0 auto;
}

.war_card h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #ff0000;
  margin-top: 30px;
}

.war_card h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
}

.war_list {
  margin-top: 20px;
}

.war_list ul {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin: 0;
  padding: 0;
  list-style: none;
}

.war_list ul li {
  padding: 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.war_list ul li span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ff0000;
}

.upload_img {
  text-align: center;
  border: 2px dashed #ffffff;
}
.upload_img .image-upload {
  /* padding: 160px 0; */
}
.upload_img .image-upload label {
  font-size: 20px;
  margin-top: 20px;
}
.upload_img .image-upload i {
  font-size: 80px;
}
.upload_img .image-upload > input {
  display: none;
}
.war_card {
  margin-bottom: 50px;
}
.war_card_scroll h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 40px;
}
.confirm_button {
  text-align: center;
  margin-top: 50px;
}
.heading_text h2 {
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
}

.heading_text p {
  color: #fff;
  font-size: 18px;
}

.heading_text p i {
  color: #ff0000;
  padding-right: 10px;
}

.confirm_button a {
  border: 2px solid #ff0000;
  color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 600;
}

/* .warzone_field,
.war_card_scroll {
  overflow-y: hidden;
  height: auto;
  overflow-x: hidden;
  padding: 0 50px 0 0;
} */

/* width */

/* .warzone_field::-webkit-scrollbar,
.war_card_scroll::-webkit-scrollbar {
  width: 15px;
}

.warzone_field,
.war_card_scroll {
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgb(202, 202, 202);
} */

/* Track */

/* .warzone_field::-webkit-scrollbar-track,
.war_card_scroll::-webkit-scrollbar-track {
  border: 1px solid #ffffff;
  border-radius: 10px;
} */

/* Handle */

/* .warzone_field::-webkit-scrollbar-thumb,
.war_card_scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
} */

/* Handle on hover */

/* .warzone_field::-webkit-scrollbar-thumb:hover,
.war_card_scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */

.create_battlefield .modal-content {
  background-color: #000;
}
.create_battlefield button.btn-close {
  color: #ff0000;
  opacity: 1;
  font-size: 45px;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  background: transparent;
}
.create_battlefield .btn-close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 1;
}
.create_battlefield .modal-header {
  border-bottom: none;
}
.card_box h5 {
  color: #fff;
}
.create_battlefield .modal-title span {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
}

.create_battlefield .modal-title {
  color: #ff0000;
  font-size: 40px;
  font-weight: bold;
  border-left: 1px solid #ff0000;
  padding-left: 20px;
}
.create_button button.btn.btn-primary {
  background: transparent;
  border: 2px solid #ff0000;
}

.create_button button.btn.btn-primary:hover {
  background: #ff0000;
  border: 2px solid #ff0000;
}

.war-history-tab {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  text-align: center;
  gap: 20px;
  padding: 0;
}
.war-history-tab li {
  border: 2px solid #ff0000;
  color: #fff;
  font-size: 1.5rem;
  padding: 25px 0px;
  border-radius: 8px;
  text-decoration: none;
  list-style-type: none;
  background-image: url("../../../../assets/banner/War-landing-page.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.war-history-tab li:hover {
  background-color: red;
}
.war-history-tab li a {
  color: #fff;
  text-decoration: none;
}
@media (max-width: 991px) and (min-width: 769px) {
  .war_vs {
    width: 120px;
    height: 120px;
  }
  .war_vs strong {
    line-height: 120px;
  }
  .battlefield_content ul li img {
    width: 100%;
    height: 100%;
  }
  .war-history-tab {
    grid-template-columns: 3fr 3fr;
    text-align: center;
    gap: 20px;
    padding: 0;
  }
}
@media (max-width: 767px) and (min-width: 651px) {
  .warzone_section {
    text-align: center;
    padding: 80px 0;
  }
  .warzone_section h1 {
    font-size: 43px;
    line-height: 50px;
  }
  .warzone_field,
  .war_card_scroll {
    padding: 0 20px 0 0;
  }
  .war_vs strong {
    line-height: 80px;
    font-size: 26px;
  }
  .war_vs {
    width: 80px;
    height: 80px;
  }
  .war_battle_card .row {
    row-gap: 70px;
  }
}
@media (max-width: 650px) {
  .card_tab .nav-tabs {
    display: grid;
    grid-template-columns: 5fr 5fr;
  }
  .war-history-tab {
    grid-template-columns: 3fr;
  }
}

@media (max-width: 480px) {
  .battlefield_content ul li {
    text-align: center;
    padding: 13px 0;
  }
  .battlefield_content ul {
    grid-template-columns: 5fr;
  }
  .create_zone .war_vs strong {
    font-size: 25px;
    line-height: 80px;
  }
  .create_zone .war_vs {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .create_zone form label {
    font-size: 22px;
  }
  .create_button button {
    font-size: 18px;
  }
  .create_zone .form-check input:checked + label:after {
    top: 8px;
  }
  .battlefield_content h4 {
    font-size: 30px;
  }
}
