.pagination-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 50px;
    
}
.pagination-button h3{
    opacity: 1;
    color: white;
}
.pagination-button p{
    margin-bottom: 0.5rem!important;

}
.pagination,.selected{
    margin: 10px;
    padding-left: 10px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    /* height: 40px; */
    padding: 10px;
    font-size: 2rem;
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-family:'Orbitron', sans-serif !important;
    line-height: 1;
}
.pagination:hover,.selected:hover{
    background-color: #ff0000;
}
.selected{
   
    background-color: #ff0000;
   
}
.pagination{
    border: 2px solid red;
    background-color: #000;
}
.disable{
    margin: 0 30px;
    width: 140px!important;
}
.disable:disabled{
   opacity: .5;
}
.disable:disabled:hover{
    background-color: #000;
}
