.Page_story_section {
    background-image: url("../Story/Sections/Images/story_banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 480px;
  }
 /*  .Page_story_section {
      text-align: center;
      padding: 120px 0;
  }
  
  .Page_story_section h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 73px;
      line-height: 92px;
      text-align: center;
      color: #ffffff;
  } */

  .story_text_box h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
}

.story_text_box p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}


.story_text_box a.story {
    border: 2px solid #EC0909;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 8px 40px;
}

@media (max-width: 767px) {
	.Page_story_section {
		text-align: center;
		padding: 80px 0;
	}
	.Page_story_section h1 {
		font-size: 43px;
		line-height: 50px;
	}
	}