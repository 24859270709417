.terms {
  text-align: justify;
  color: white;
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
}
.terms span {
  text-align: right !important;
}
