.breeding-list-banner{
    background-image: url(../../../../../../assets/banner/Breeding-lab-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 120px 0;
}
.breeding-list-banner h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 73px;
    line-height: 92px;
    text-align: center;
    color: #ffffff;
    font-family: "Chakra Petch", sans-serif;
  }